import { axiosOnce as axios } from '@/axios_once'

export default {
  getProfile() {
    return axios.get('/api/profile')
  },
  updateProfile(params) {
    return axios.post('/api/profile', params)
  },
  updateProfileOption(params) {
    return axios.post('/api/profile', params)
  },
  setProfileLocationDetectionDisabled() {
    return axios.patch('/api/profile/set_location_detection_disabled')
  },
  addPosition({ lat, lng }) {
    return axios.post('/api/user_positions', { user_position: { lat: lat, lng: lng } })
  }
}
